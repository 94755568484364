export default {
  CORP_NAME: 'AkeRio',
  CORP_SUBTITLE: 'HelpDesk',
  APP_NAME: 'AkeRio HelpDesk',
  LOGIN_FORM_CAPTION: 'Вход',
  LOGIN_FORM_LOGIN_LABEL: 'Логин',
  LOGIN_FORM_PASSWORD_LABEL: 'Пароль',
  APP_EXIT: 'Выйти',
  FIELD_REQUIRED: 'Это поле необходимо заполнить',
  WRONG_EMAIL: 'Укажите правильную почту',
}
