import lsp from '@/lib/LocalStoragePack'
import Api from '@/lib/apiCall'

const state = {
  token: lsp.loadSimple('token') || null,
  user: lsp.loadObject('user') || {},
}

const getters = {
  getToken: state => state.token,
  //getUser: state => state.user,
  getUserId: state => state.user?.id || null,
  getUserName: state => state.user?.name || '',
  getUserRoles: state => state.user?.roles?.map(i => i.value) || [],
}

const mutations = {
  SET_LOGIN: (state, { token, user }) => {
    state.token = token
    state.user = user
    lsp.saveSimple('token', token)
    lsp.saveObject('user', user)
  },

  SET_LOGOUT: state => {
    state.token = null
    state.user = {}
    localStorage.removeItem('token')
    localStorage.removeItem('user')
  },
}

const actions = {
  LOGIN: async ({ commit }, candidate) => {
    try {
      const { token, user } = await Api.post('/auth/login', candidate)
      commit('SET_LOGIN', { token, user })
    } catch (error) {
      commit('SET_LOGOUT')
      throw error
    }
  },

  LOGOUT: async ({ commit }) => {
    commit('SET_LOGOUT')
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
