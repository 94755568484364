import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView'
import Strings from '@/store/const/Strings'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: {
      layout: 'DefaultLayout',
      auth: true,
    },
  },
  {
    path: '/notes',
    name: 'Notes',
    component: () => import(/* webpackChunkName: "Work" */ '@/views/NotesView'),
    meta: {
      layout: 'DefaultLayout',
      title: 'Заметки',
      auth: true,
    },
  },
  {
    path: '/files',
    name: 'Files',
    component: () => import(/* webpackChunkName: "Work" */ '@/views/FilesView'),
    meta: {
      layout: 'DefaultLayout',
      title: 'Файлы',
      auth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/LoginView'),
    meta: {
      layout: 'EmptyLayout',
      auth: false,
    },
  },
  {
    path: '/page-not-found',
    name: 'page-not-found',
    alias: '*',
    meta: {
      layout: 'EmptyLayout',
      title: 'Страница не найдена',
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "p404" */ '@/views/Page404View'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(/* webpackChunkName: "Admin" */ '@/views/admin/MainAdminView'),
    meta: {
      //layout: 'DefaultLayout',
      //title: 'Администрирование',
    },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: () =>
          import(
            /* webpackChunkName: "Admin" */ '@/views/admin/UsersAdminView'
          ),
        meta: {
          layout: 'DefaultLayout',
          title: 'Пользователи',
        },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const isAuthorized = () => {
  return store.getters.getToken
}

const docTitle = to => {
  return to.meta.title ?? Strings.APP_NAME
}

router.beforeEach((to, from, next) => {
  document.title = docTitle(to)
  const ra = to.meta?.auth ?? true

  if (ra && !isAuthorized()) {
    const redirect = to.fullPath ?? undefined
    return next({ name: 'Login', query: { redirect } })
  }

  next()
})

export default router
