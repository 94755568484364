<template>
  <v-navigation-drawer
    app
    v-model="showDrawer"
    style="background-color: #f5f5f5"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="@/assets/logo.svg"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h7">
          {{ Strings.CORP_NAME }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ Strings.CORP_SUBTITLE }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <hr
      role="separator"
      aria-orientation="horizontal"
      class="mb-2 v-divider theme--dark mx-5"
    />

    <v-list density="compact" nav dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <div v-for="item in checkRole" :key="item.title">
          <v-subheader
            v-if="item.type && item.type === 'subheader'"
            v-html="item.title"
          ></v-subheader>
          <v-list-item v-else link :to="item.link">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content v-html="item.title"></v-list-item-content>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import Strings from '@/store/const/Strings'

export default {
  name: 'NavComponent',
  model: {
    prop: 'drawer',
    event: 'drawerActivity',
  },

  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  watch: {
    showDrawer(val) {
      this.$emit('drawerActivity', val)
    },
    drawer(val) {
      this.showDrawer = val
    },
  },
  computed: {
    ...mapGetters(['getUserRoles']),
    checkRole() {
      return this.items.filter(i => {
        let t = false

        for (const iKey in i.roles) {
          if (this.getUserRoles.includes(i.roles[iKey])) {
            t = true
          }
        }

        return t
      })
    },
    Strings() {
      return Strings
    },
  },
  data: () => ({
    showDrawer: null,
    selectedItem: 0,
    items: [
      {
        title: 'Работа',
        icon: null,
        roles: ['ADMIN', 'USER', 'GUEST'],
        type: 'subheader',
      },
      {
        title: 'Заметки',
        icon: 'mdi-playlist-edit',
        roles: ['ADMIN', 'USER', 'GUEST'],
        link: '/notes',
      },
      {
        title: 'Файлы',
        icon: 'mdi-file-arrow-left-right',
        roles: ['ADMIN', 'USER', 'GUEST'],
        link: '/files',
      },
      /* ADMIN */
      { title: 'Управление', icon: null, roles: ['ADMIN'], type: 'subheader' },
      {
        title: 'Пользователи',
        icon: 'mdi-account-group',
        roles: ['ADMIN'],
        link: '/admin/users',
      },
    ],
  }),
}
</script>

<style scoped>
>>> .v-image__image--cover {
  background-size: 30px;
}
>>> .v-list-item__icon {
  margin-right: 0;
}
</style>
