import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuelidate from 'vuelidate'
import DefaultLayout from './views/layout/DefaultLayout'
import EmptyLayout from './views/layout/EmptyLayout'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import '@/assets/css/style.css'

Vue.config.productionTip = false

const toastOptions = {
  timeout: 2000,
}

Vue.use(Toast, toastOptions)
Vue.use(Vuelidate)

Vue.component('DefaultLayout', DefaultLayout)
Vue.component('EmptyLayout', EmptyLayout)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
