<template>
  <v-app>
    <component :is="layout" v-if="layout"> </component>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({}),
  computed: {
    layout() {
      return this.$route?.meta?.layout || false
    },
  },
}
</script>

<style>
::-webkit-scrollbar {
  width: calc(6px);
  height: calc(6px);
}

::-webkit-scrollbar-track {
  cursor: pointer !important;
  background: white;
}

::-webkit-scrollbar-thumb {
  cursor: pointer !important;
  background: #c4c6c4;
  border-radius: calc(5px);
}
</style>
