import axios from 'axios'
import store from '@/store'

export const GET = 'GET'
export const POST = 'POST'
export const DELETE = 'DELETE'
export const PATCH = 'PATCH'
export const PUT = 'PUT'

export default class Api {
  static development = process.env.NODE_ENV !== 'production'
  static axiosConfig = {
    baseURL: this.development
      ? 'http://localhost:3000'
      : 'https://api.akerio.ru',
    headers: {
      'Content-type': 'application/json',
    },
  }
  static axios = null
  static token = () => {
    return store.getters.getToken
  }

  static async getConfig() {
    const token = this.token()
    if (token) this.axiosConfig.headers['Authorization'] = `Bearer ${token}`
    return (this.axios = await axios.create(this.axiosConfig))
  }

  static async doAxios(uri = '', data, method) {
    try {
      let response = null
      await this.getConfig()
      if (method === GET || !method) response = await this.axios.get(uri)
      if (method === POST || !method)
        response = await this.axios.post(uri, data)
      return response
    } catch (error) {
      const code = error?.response?.status || null
      switch (code) {
        case 404:
          console.log('NOT FOUND')
          throw new Error()
        default:
          throw error
      }
    }
  }

  static async get(url = null) {
    const { data } = await this.doAxios(url, GET)
    return data
  }

  static async post(url = null, dt = {}) {
    const { data } = await this.doAxios(url, dt, POST)
    return data
  }
}
