<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="150"
        />
      </v-col>

      <v-col class="mb-4">
        <h2 class="font-weight-bold mb-3">
          Добро пожаловать в AkeRio HelpDesk!
        </h2>
      </v-col>

      <v-col class="mb-5" cols="12"> </v-col>

      <v-col class="mb-5" cols="12"> </v-col>

      <v-col class="mb-5" cols="12"> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'HelloWorld',

  data: () => ({}),
}
</script>
