<template>
  <div>
    <NavComponent v-model="drawer" />

    <v-app-bar app elevation="1">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      {{ getUserName }}
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="black" v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="Logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ Strings.APP_EXIT }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex/dist/vuex.mjs'
import Strings from '@/store/const/Strings'
import NavComponent from '@/components/NavComponent'

export default {
  name: 'DefaultLayout',
  components: { NavComponent },
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters(['getUserName']),
    Strings() {
      return Strings
    },
  },
  methods: {
    Logout() {
      this.$store.dispatch('LOGOUT').then(() => this.$router.push('/login'))
    },
  },
}
</script>
