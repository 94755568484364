<template>
  <v-main class="hero">
    <router-view></router-view>
  </v-main>
</template>

<script>
export default {
  name: 'EmptyLayout',
}
</script>
